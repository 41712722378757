/* Some Sass variables */
body {
  margin: 0;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 1.3em;
}

.ui {
  height: 100vh;
  display: grid;
  grid-template-rows: 40px 50px 1fr;
  background-color: #0079bf;
  color: #eee;
}

.navbar {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.navbar.app {
  background-color: #0067a3;
  font-size: 1.5rem;
}
.navbar.board {
  font-size: 1.1rem;
}

.lists {
  display: flex;
  overflow-x: auto;
}
.lists > * {
  flex: 0 0 auto;
  margin-left: 10px;
}
.lists::after {
  content: "";
  flex: 0 0 10px;
}

.list {
  width: 300px;
  height: calc(100% - 10px - 17px);
}
.list > * {
  background-color: #e2e4e6;
  color: #333;
  padding: 0 10px;
}
.list header {
  line-height: 36px;
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.list footer {
  line-height: 36px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #888;
}
.list ul {
  list-style: none;
  margin: 0;
  max-height: calc(100% - 36px - 36px);
  overflow-y: auto;
}
.list ul li {
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.list ul li:not(:last-child) {
  margin-bottom: 10px;
}
.list ul li img {
  display: block;
  width: calc(100% + 2 * 10px);
  margin: -10px 0 10px -10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
