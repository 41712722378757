body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}
.fw-800 {
  font-weight: 800;
}
.header-text {
  background: -webkit-linear-gradient(#355ebe 0%, #1b45ce 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.code-container {
  height: 100px;
  overflow-y: scroll;
}
.template-wrapper {
  overflow: auto;
  white-space: nowrap;
}
.selected-template-style img {
  border: 3px solid #65e669;
}
.tools-main-view {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.over-flow-x {
  overflow-x: auto !important;
}
.tool-list {
  width: 260px;
}
.tool-list:hover {
  -webkit-box-shadow: 0px 6px 0px -2px #dc3545;
  -moz-box-shadow: 0px 6px 0px -2px #dc3545;
  box-shadow: 0px 6px 0px -2px #dc3545;
}
.tool-list:active {
  transform: translateY(3px);
  box-shadow: none;
}
.darkmode-primary {
  background-color: #1f1b24;
}
.darkmode-bg {
  background-color: #121212;
}
@media (max-width: 575.98px) {
  .tool-list {
    width: 100% !important;
  }
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("font/poppins-bold-webfont.woff2") format("woff2"),
    url("font/poppins-bold-webfont.woff") format("woff"),
    url("font/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("font/poppins-extrabold-webfont.woff2") format("woff2"),
    url("font/poppins-extrabold-webfont.woff") format("woff"),
    url("font/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("font/poppins-medium-webfont.woff2") format("woff2"),
    url("font/poppins-medium-webfont.woff") format("woff"),
    url("font/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("font/poppins-regular-webfont.woff2") format("woff2"),
    url("font/poppins-regular-webfont.woff") format("woff"),
    url("font/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("font/poppins-semibold-webfont.woff2") format("woff2"),
    url("font/poppins-semibold-webfont.woff") format("woff"),
    url("font/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
